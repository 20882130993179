import * as React from "react";
import * as ReactDOM from "react-dom";
import { Icon } from "@blueprintjs/core";

function Footer() {
    const [showFooter, setShowFooter] = React.useState(true);

    return (
        <div className="footer">
            {showFooter && <span>
                <Icon icon="small-cross" onClick={() => setShowFooter(false)}></Icon>
                Photo by <a href="https://unsplash.com/photos/oc7tGf44rM0">Jesper Aggergaard</a> on <a href="https://unsplash.com/">Unsplash</a>
            </span>}
        </div>
    );
}

export default Footer;
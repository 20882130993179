import * as React from "react";
import * as ReactDOM from "react-dom";

function Header() {
    return (
        <div className="header">
            <h1>Squat Rack City</h1>
            <h2>Find hotels with <em>real</em> gyms</h2>
        </div>
    );
}

export default Header;
import * as React from "react";
import * as ReactDOM from "react-dom";

import { Button, Card, Elevation, FormGroup, MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import cities from "../../data/cities";

function SearchBox() {
    const [city, setCity] = React.useState();
    const [searchCity, setSearchCity] = React.useState();

    const CitySelect = Select.ofType<string>();

    const filterCity: ItemPredicate<string> = (query, city) => {
        return city.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    };

    const renderCity: ItemRenderer<string> = (city, { handleClick, modifiers}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }

        return (
            <MenuItem
                active={modifiers.active}
                key={city}
                label={city}
                onClick={handleClick}
                text={city}
            />
        );
    };

    const searchDisabled = !city;
    const noResultsComponent = (
        <MenuItem
            text={"We don't operate in " + searchCity + " yet. Click here to suggest it!"}
            onClick={() => suggestCity()}
        />
    );

    const suggestCity = () => {
        window.open(
            "https://docs.google.com/forms/d/18IBQkxugUAWJyusq_2q10AEh44ZaixMdHyBAPCYGzRY/viewform?entry.261650969="
                + encodeURI(searchCity),
            "_blank"
        );
    };

    return (
        <Card className="search-box" elevation={Elevation.TWO}>
            <CitySelect
                items={cities}
                itemPredicate={filterCity}
                itemRenderer={renderCity}
                noResults={noResultsComponent}
                onItemSelect={city => setCity(city)}
                onQueryChange={(query => setSearchCity(query))}
            >
                <Button text={city || "Where are you going?"} rightIcon="double-caret-vertical" />
            </CitySelect>
            <br />
            <Button disabled={searchDisabled}>{city ? "Find Hotels in " + city : "Find hotels"}</Button>
        </Card>
    )
}

export default SearchBox;

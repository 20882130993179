import React, { Component } from "react";
import ReactGA from "react-ga";

import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import SearchBox from "./components/search-box/search-box";

ReactGA.initialize('UA-138650574-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <SearchBox />
        <Footer />
      </div>
    );
  }
}

export default App;

const cities = [
    "Chicago",
    "Los Angeles",
    "Miami",
    "New York City",
    "San Francisco",
    "Seattle",
    "Washington, D.C.",
];

export default cities;